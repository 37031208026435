import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Text, Flex, Box, Skeleton, ArrowBackIcon, ArrowForwardIcon } from '@cakewswap/uikit';
import { formatAmount } from 'views/Info/utils/formatInfoNumbers'
import { Bridge } from 'state/types';
import { ITEMS_PER_INFO_TABLE_PAGE } from 'config/constants/info'
import { DoubleCurrencyLogo } from 'views/Info/components/CurrencyLogo'
import { useTranslation } from 'contexts/Localization'
import { ClickableColumnHeader, TableWrapper, PageButtons, Container, Arrow, Break } from './shared'

/**
 *  Columns on different layouts
 *  5 = | # | Bridge | TVL | Volume 24H | Volume 7D |
 *  4 = | # | Bridge |     | Volume 24H | Volume 7D |
 *  3 = | # | Bridge |     | Volume 24H |           |
 *  2 = |   | Bridge |     | Volume 24H |           |
 */

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 20px 2.5fr repeat(4, 1fr);

  padding: 0 24px;
  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(3, 1fr);
    & :nth-child(4),
    & :nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 20px 1.5fr repeat(1, 1fr);
    & :nth-child(4),
    & :nth-child(5),
    & :nth-child(6),
    & :nth-child(7) {
      display: none;
    }
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 2.5fr repeat(1, 1fr);
    > *:nth-child(1) {
      display: none;
    }
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const SORT_FIELD = {
  volumeUSD: 'volumeUSD',
  tvlUSD: 'tvlUSD',
  volumeUSDWeek: 'volumeUSDWeek',
  lpFees24h: 'lpFees24h',
  lpApr7d: 'lpApr7d',
}

const LoadingRow: React.FC = () => (
  <ResponsiveGrid>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </ResponsiveGrid>
)

const TableLoader: React.FC = () => (
  <>
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
  </>
)

const DataRow = ({ bridge, index }: { bridge: Bridge; index: number }) => {
  return (
    <LinkWrapper to="#">
      <ResponsiveGrid>
        <Text>{index + 1}</Text>
        <Flex>
          <DoubleCurrencyLogo address0={bridge.inputCurrency.address} address1={bridge.outCurrency.address} />
          <Text ml="8px">
            {bridge.inputCurrency.symbol}/{bridge.outCurrency.symbol}
          </Text>
        </Flex>
        <Text>${formatAmount(bridge.amountIn)}</Text>
        <Text>${formatAmount(bridge.amountOut)}</Text>
        <Text>${formatAmount(bridge.amountOut)}</Text>
        <Text>${formatAmount(bridge.amountOut)}</Text>
      </ResponsiveGrid>
    </LinkWrapper>
  )
}

interface BridgeTableProps {
  bridges: Bridge[]
  loading?: boolean // If true shows indication that SOME Bridges are loading, but the ones already fetched will be shown
}

const BridgeTable: React.FC<BridgeTableProps> = ({ bridges, loading }) => {
  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.volumeUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const { t } = useTranslation()

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  useEffect(() => {
    let extraPages = 1
    if (bridges.length % ITEMS_PER_INFO_TABLE_PAGE === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(bridges.length / ITEMS_PER_INFO_TABLE_PAGE) + extraPages)
  }, [bridges])

  const sortedBridges = useMemo(() => {
    return bridges
      ? bridges
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof Bridge] > b[sortField as keyof Bridge]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            }
            return -1
          })
          .slice(ITEMS_PER_INFO_TABLE_PAGE * (page - 1), page * ITEMS_PER_INFO_TABLE_PAGE)
      : []
  }, [page, bridges, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      const directionArrow = !sortDirection ? '↑' : '↓'
      return sortField === field ? directionArrow : ''
    },
    [sortDirection, sortField],
  )

  return (
    <Container>
      <TableWrapper>
        <ResponsiveGrid>
          <Text color="secondary" fontSize="12px" bold>
            #
          </Text>
          <Text color="secondary" fontSize="12px" bold textTransform="uppercase">
            {t('Bridge')}
          </Text>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            onClick={() => handleSort(SORT_FIELD.volumeUSD)}
            textTransform="uppercase"
          >
            {t('Currency In')} {arrow(SORT_FIELD.volumeUSD)}
          </ClickableColumnHeader>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            onClick={() => handleSort(SORT_FIELD.volumeUSDWeek)}
            textTransform="uppercase"
          >
            {t('Currency In')} {arrow(SORT_FIELD.volumeUSDWeek)}
          </ClickableColumnHeader>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            onClick={() => handleSort(SORT_FIELD.volumeUSDWeek)}
            textTransform="uppercase"
          >
            {t('Status')} {arrow(SORT_FIELD.volumeUSDWeek)}
          </ClickableColumnHeader>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            onClick={() => handleSort(SORT_FIELD.volumeUSDWeek)}
            textTransform="uppercase"
          >
            {t('Actions')} {arrow(SORT_FIELD.volumeUSDWeek)}
          </ClickableColumnHeader>
        </ResponsiveGrid>
        <Break />
        {sortedBridges.length > 0 ? (
          <>
            {sortedBridges.map((bridge, i) => {
              if (bridge) {
                return (
                  <React.Fragment key={bridge.tx}>
                    <DataRow index={(page - 1) * ITEMS_PER_INFO_TABLE_PAGE + i} bridge={bridge} />
                    <Break />
                  </React.Fragment>
                )
              }
              return null
            })}
            {loading && <LoadingRow />}
            <PageButtons>
              <Arrow
                onClick={() => {
                  setPage(page === 1 ? page : page - 1)
                }}
              >
                <ArrowBackIcon color={page === 1 ? 'textDisabled' : 'primary'} />
              </Arrow>

              <Text>{t('Page %page% of %maxPage%', { page, maxPage })}</Text>

              <Arrow
                onClick={() => {
                  setPage(page === maxPage ? page : page + 1)
                }}
              >
                <ArrowForwardIcon color={page === maxPage ? 'textDisabled' : 'primary'} />
              </Arrow>
            </PageButtons>
          </>
        ) : (
          <>
            <TableLoader />
            <Box />
          </>
        )}
      </TableWrapper>
    </Container>
  )
}

export default BridgeTable

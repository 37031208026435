import sample from 'lodash/sample'

// Array of available nodes to connect to
export const nodes = ['https://bsc-dataseed1.binance.org', 'https://bsc-dataseed1.defibit.io']

const getNodeUrl = () => {
  return sample(nodes)
}

export default getNodeUrl

export default {
  masterChef: {
    10001: '0xdC64d09155629E6872Fd2180056672073D554957',
    56: '0xB6049159a71159178f7b0942c8C74F0a82BbAB62',
  },
  multiCall: {
    56: '0xcA11bde05977b3631167028862bE2a173976CA11',
    10001: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  multiCall2: {
    56: '0xcA11bde05977b3631167028862bE2a173976CA11',
    10001: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  pancakeProfile: {
    56: '0x0F0Eb44122AcaDb9320FEA4C458a0D8E6B6F7bc0',
    10001: '0x0F0Eb44122AcaDb9320FEA4C458a0D8E6B6F7bc0',
  },
  systemVault: {
    56: '0xc6260C4691A65Ff36c18798B1B3a1a5d20eC8cf6',
    10001: '0x5550905ebF36A64ffC4D25Fc5A4febFeD12c73b0',
  },
  system: {
    56: '0x2876992CDD3B48a08375768D7FC7f4ac7154D114',
    10001: '0x8a521189BfEDf99b1Dc84BC21571cF8B799DbD4D',
  },
  weth: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    10001: '0x7bf88d2c0e32de92cdaf2d43ccdc23e8edfd5990',
  },
}

import { ChainId } from '@cakewswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = '56'
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getTokenSystemAddress = () => {
  return getAddress(addresses.system)
}

export const getTokenVaultAddress = () => {
  return getAddress(addresses.systemVault)
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}

export const getWETHAddress = () => {
  return getAddress(addresses.weth)
}

export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}

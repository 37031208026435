import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { simpleRpcProvider } from 'utils/providers'
import useRefresh from 'hooks/useRefresh'
import {
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  fetchCakeWVaultPublicData,
  fetchCakeWVaultUserData,
  fetchCakeWVaultFees,
  fetchPoolsStakingLimitsAsync,
} from '.'
import { State, DeserializedPool as Pool } from '../types'
import { transformPool } from './helpers'

export const useFetchPublicPoolsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await simpleRpcProvider.getBlockNumber()
      dispatch(fetchPoolsPublicDataAsync(blockNumber))
    }

    fetchPoolsPublicData()
    dispatch(fetchPoolsStakingLimitsAsync())
  }, [dispatch, slowRefresh])
}

export const usePools = (account): { pools: Pool[]; userDataLoaded: boolean } => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const { pools, userDataLoaded } = useSelector((state: State) => ({
    pools: state.pools.data,
    userDataLoaded: state.pools.userDataLoaded,
  }))
  return { pools: pools.map(transformPool), userDataLoaded }
}

export const useFetchCakeWVault = () => {
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCakeWVaultPublicData())
  }, [dispatch, fastRefresh])

  useEffect(() => {
    dispatch(fetchCakeWVaultUserData({ account }))
  }, [dispatch, fastRefresh, account])

  useEffect(() => {
    dispatch(fetchCakeWVaultFees())
  }, [dispatch])
}

export const useTokenSystemVault = () => {
  const {
    totalShares: totalSharesAsString,
    pricePerFullShare: pricePerFullShareAsString,
    totalCakeWInVault: totalCakeWInVaultAsString,
    estimatedCakeWBountyReward: estimatedCakeWBountyRewardAsString,
    totalPendingCakeWHarvest: totalPendingCakeWHarvestAsString,
    fees: { performanceFee, callFee, withdrawalFee, withdrawalFeePeriod },
    userData: {
      isLoading,
      userShares: userSharesAsString,
      cakeWAtLastUserAction: cakeWAtLastUserActionAsString,
      lastDepositedTime,
      lastUserActionTime,
    },
  } = useSelector((state: State) => state.pools.cakeWVault)

  const estimatedCakeWBountyReward = useMemo(() => {
    return new BigNumber(estimatedCakeWBountyRewardAsString)
  }, [estimatedCakeWBountyRewardAsString])

  const totalPendingCakeWHarvest = useMemo(() => {
    return new BigNumber(totalPendingCakeWHarvestAsString)
  }, [totalPendingCakeWHarvestAsString])

  const totalShares = useMemo(() => {
    return new BigNumber(totalSharesAsString)
  }, [totalSharesAsString])

  const pricePerFullShare = useMemo(() => {
    return new BigNumber(pricePerFullShareAsString)
  }, [pricePerFullShareAsString])

  const totalCakeWInVault = useMemo(() => {
    return new BigNumber(totalCakeWInVaultAsString)
  }, [totalCakeWInVaultAsString])

  const userShares = useMemo(() => {
    return new BigNumber(userSharesAsString)
  }, [userSharesAsString])

  const cakeWAtLastUserAction = useMemo(() => {
    return new BigNumber(cakeWAtLastUserActionAsString)
  }, [cakeWAtLastUserActionAsString])

  return {
    totalShares,
    pricePerFullShare,
    totalCakeWInVault,
    estimatedCakeWBountyReward,
    totalPendingCakeWHarvest,
    fees: {
      performanceFee,
      callFee,
      withdrawalFee,
      withdrawalFeePeriod,
    },
    userData: {
      isLoading,
      userShares,
      cakeWAtLastUserAction,
      lastDepositedTime,
      lastUserActionTime,
    },
  }
}

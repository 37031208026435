import { ChainId, Token, WETH } from '@cakewswap/sdk'
import { getAddress } from '@ethersproject/address'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const CakeW: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    getAddress('0x2876992CDD3B48a08375768D7FC7f4ac7154D114'),
    18,
    'CAKEW',
    'CakeWSwap',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    getAddress('0x2876992CDD3B48a08375768D7FC7f4ac7154D114'),
    18,
    'CAKEW',
    'CakeWSwap',
  ),
}

export const USDT = new Token(
  ChainId.MAINNET,
  getAddress('0x55d398326f99059ff775485246999027b3197955'),
  18,
  'USDT',
  'Tether USD',
)

export const USDW = new Token(
  ChainId.MAINNET,
  getAddress('0x5186c4Fb5752Eb4A952C7EF03fAE3e543f48383e'),
  6,
  'USDW',
  'Tether Pow',
)

export const ETH = new Token(
  ChainId.MAINNET,
  getAddress('0x2170Ed0880ac9A755fd29B2688956BD959F933F8'),
  18,
  'ETH',
  'Ethereum',
)

export const USDC = new Token(
  ChainId.MAINNET,
  getAddress('0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'),
  18,
  'USDC',
  'USD Coin',
)

export const mainnetTokens = {
  eth: ETH,
  usdt: USDT,
  usdw: USDW,
  usdc: USDC,
  weth: WETH[ChainId.MAINNET],
  cakew: new Token(MAINNET, getAddress('0x2876992CDD3B48a08375768D7FC7f4ac7154D114'), 18, 'CAKEW', 'CakeWSwap', '#'),
  busd: new Token(MAINNET, getAddress('0xe9e7cea3dedca5984780bafc599bd69add087d56'), 18, 'BUSD', 'Binance USD', '#'),
}

const tokens = (): TokenList => mainnetTokens as any

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = mainnetTokens

  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()

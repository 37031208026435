import React, { CSSProperties, MutableRefObject, useCallback } from 'react'
import { Text } from '@cakewswap/uikit';
import { Chain, chainEquals } from 'types';
import styled from 'styled-components'
import { FixedSizeList } from 'react-window'
import Column from '../Layout/Column'
import { RowBetween } from '../Layout/Row'
import { ChainLogo } from '../Logo'

function chainKey(chain: Chain): string {
  return chain?.chainId?.toString();
}

const MenuItem = styled(RowBetween)<{ disabled: boolean; selected: boolean }>`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0, 72px);
  grid-gap: 8px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.background};
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`

function ChainRow({
  chain,
  onSelect,
  isSelected,
  otherSelected,
  style,
}: {
  chain: Chain
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
}) {
  const key = chainKey(chain)

  // only show add or remove buttons if not on selected list
  return (
    <MenuItem
      style={style}
      className={`chain-item-${key}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected}
      selected={otherSelected}
    >
      <ChainLogo chain={chain} size="24px" />
      <Column>
        <Text bold>{chain?.name}</Text>
      </Column>
    </MenuItem>
  )
}

export default function ChainList({
  height,
  chains,
  selectedChain,
  onChainSelect,
  otherChain,
  fixedListRef,
}: {
  height: number
  chains: Chain[]
  selectedChain?: Chain | null
  onChainSelect: (chain: Chain) => void
  otherChain?: Chain | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
}) {
  const Row = useCallback(
    ({ data, index, style }) => {
      const chain: Chain = data[index]
      const isSelected = Boolean(selectedChain && chainEquals(selectedChain, chain))
      const otherSelected = Boolean(otherChain && chainEquals(otherChain, chain))
      const handleSelect = () => onChainSelect(chain)

      return (
        <ChainRow
          style={style}
          chain={chain}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      )
    },
    [
      onChainSelect,
      otherChain,
      selectedChain,
    ],
  )

  const itemKey = useCallback((index: number, data: any) => chainKey(data[index]), [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={chains}
      itemCount={chains.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}

import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const tokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.cakew,
    earningToken: tokens.cakew,
    contractAddress: {
      10001: '0xdC64d09155629E6872Fd2180056672073D554957',
      56: '0xB6049159a71159178f7b0942c8C74F0a82BbAB62',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.25',
    sortOrder: 1,
    isFinished: false,
  },
]

export default pools

import React, { useCallback, useState } from 'react'
import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBackButton,
  ModalCloseButton,
  ModalBody,
  InjectedModalProps,
  Heading,
} from '@cakewswap/uikit';
import { Chain } from 'types';
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import ChainSearch from './ChainSearch'
import { ChainModalView } from './types'

const StyledModalContainer = styled(ModalContainer)`
  max-width: 420px;
  width: 100%;
`

const StyledModalBody = styled(ModalBody)`
  padding: 24px;
`

interface ChainSearchModalProps extends InjectedModalProps {
  selectedChain?: Chain | null
  onChainSelect: (chain: Chain) => void
  otherSelectedChain?: Chain | null
}

export default function ChainSearchModal({
  onDismiss = () => null,
  onChainSelect,
  selectedChain,
  otherSelectedChain,
}: ChainSearchModalProps) {
  const [modalView] = useState<ChainModalView>(ChainModalView.search)

  const handleChainSelect = useCallback(
    (chain: Chain) => {
      onDismiss()
      onChainSelect(chain)
    },
    [onDismiss, onChainSelect],
  )

  const { t } = useTranslation()

  const config = {
    [ChainModalView.search]: { title: t('Select a Chain'), onBack: undefined },
  }

  return (
    <StyledModalContainer minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          {config[modalView].onBack && <ModalBackButton onBack={config[modalView].onBack} />}
          <Heading>{config[modalView].title}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <StyledModalBody>
        {modalView === ChainModalView.search ? (
          <ChainSearch
            onChainSelect={handleChainSelect}
            selectedChain={selectedChain}
            otherSelectedChain={otherSelectedChain}
          />
        ) : (
          ''
        )}
      </StyledModalBody>
    </StyledModalContainer>
  )
}

import { Team } from './types'

const teams: Team[] = [
  {
    id: 1,
    name: 'Primary Artist',
    description: "Easy to start with beginner. You're a seed and shine in our sky soon",
    images: {
      lg: 'syrup-storm-lg.png',
      md: 'syrup-storm-md.png',
      sm: 'syrup-storm-sm.png',
      alt: 'syrup-storm-alt.png',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmXKzSojwzYjtDCVgR6mVx7w7DbyYpS7zip4ovJB9fQdMG/syrup-storm.png',
    },
    background: 'syrup-storm-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
  {
    id: 2,
    name: 'Gold Artist',
    description: "No one and nothing can get in your way. You're an artist and going to star",
    images: {
      lg: 'fearsome-flippers-lg.png',
      md: 'fearsome-flippers-md.png',
      sm: 'fearsome-flippers-sm.png',
      alt: 'fearsome-flippers-alt.png',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmXKzSojwzYjtDCVgR6mVx7w7DbyYpS7zip4ovJB9fQdMG/fearsome-flippers.png',
    },
    background: 'fearsome-flippers-bg.svg',
    textColor: '#FFFFFF',
    users: 0,
    points: 0,
  },
  {
    id: 3,
    name: 'Platinum Artist',
    description: 'Can you stand the heat? The place of star in NFT world',
    images: {
      lg: 'chaotic-cakers-lg.png',
      md: 'chaotic-cakers-md.png',
      sm: 'chaotic-cakers-sm.png',
      alt: 'chaotic-cakers-alt.png',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmXKzSojwzYjtDCVgR6mVx7w7DbyYpS7zip4ovJB9fQdMG/chaotic-cakers.png',
    },
    background: 'chaotic-cakers-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
]

export default teams

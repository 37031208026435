import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { updateUserBalance, updateUserPendingReward } from 'state/actions'
import { harvestFarm } from 'utils/calls'
import { BIG_ZERO } from 'utils/bigNumber'
import { useMasterchef, useMasterchefExternal, useSousChef } from 'hooks/useContract'
import { DEFAULT_GAS_LIMIT, FARMS_LIST, FARMS_EXTERANL_LIST } from 'config'
import getGaslimit from 'utils/getGaslimit'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const harvestPool = async (sousChefContract, gasLimit = DEFAULT_GAS_LIMIT) => {
  const tx = await sousChefContract.deposit('0', {
    gasLimit,
  })

  const receipt = await tx.wait()
  return receipt.status
}

const harvestPoolBnb = async (sousChefContract) => {
  const tx = await sousChefContract.deposit({ ...options, value: BIG_ZERO })
  const receipt = await tx.wait()
  return receipt.status
}

const useHarvestPool = (sousId, isUsingBnb = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const sousChefContract = useSousChef(sousId)
  const masterChefContract = useMasterchef()
  const exnteralChefContract = useMasterchefExternal(sousId)

  const handleHarvest = useCallback(async () => {
    if (FARMS_LIST.includes(sousId)) {
      await harvestFarm(masterChefContract, sousId)
    } else if (FARMS_EXTERANL_LIST.includes(sousId)) {
      await harvestFarm(exnteralChefContract, sousId)
    } else if (isUsingBnb) {
      await harvestPoolBnb(sousChefContract)
    } else {
      await harvestPool(sousChefContract, getGaslimit(sousId))
    }
    dispatch(updateUserPendingReward(sousId, account))
    dispatch(updateUserBalance(sousId, account))
  }, [account, dispatch, isUsingBnb, masterChefContract, sousChefContract, sousId, exnteralChefContract])

  return { onReward: handleHarvest }
}

export default useHarvestPool

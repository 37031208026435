import { MenuEntry } from '@cakewswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/pool',
      },
      {
        label: t('Ethereum pow'),
        href: 'https://cakewswap.finance/swap',
      },
    ],
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: t('Bridge'),
    icon: 'GroupsIcon',
    href: '/bridge',
  },
  {
    label: t('Chart'),
    icon: 'MoonIcon',
    href: '/info',
  },
  {
    label: t('Partners'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Coingecko'),
        href: 'https://www.coingecko.com/en/coins/cakewswap',
      },
      {
        label: t('Geckoterminal'),
        href: 'https://www.geckoterminal.com/ethw/cakewswap/pools',
      },
      {
        label: t('Coinmarketcap'),
        href: 'https://coinmarketcap.com/currencies/cakewswap/',
      },
    ],
  },
]

export default config

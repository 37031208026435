import React from 'react'
import { TokenPairImage, ImageProps } from '@cakewswap/uikit';
import tokens from 'config/constants/tokens'

const CakeWVaultTokenPairImage: React.FC<Omit<ImageProps, 'src'>> = (props) => {
  const primaryTokenSrc = `/images/tokens/${tokens.cakew.address.toLocaleLowerCase()}.png`

  return <TokenPairImage primarySrc={primaryTokenSrc} secondarySrc="/images/tokens/autorenew.svg" {...props} />
}

export default CakeWVaultTokenPairImage

export enum CurrencyModalView {
  search,
  manage,
  importToken,
  importList,
}

export enum ChainModalView {
  search,
  manage,
}

export default CurrencyModalView

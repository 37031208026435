import React, { KeyboardEvent, RefObject, useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { Text, Input, Box } from '@cakewswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { Chain, WBNB } from 'types'
import { FixedSizeList } from 'react-window'
import { useAudioModeManager } from 'state/user/hooks'
import useDebounce from 'hooks/useDebounce'
import { useChainActiveList } from 'state/lists/hooks'
import { isAddress } from '../../utils'
import Column, { AutoColumn } from '../Layout/Column'
import Row from '../Layout/Row'
import ChainList from './ChainList'
import { filterChains, useSortedChainsByQuery } from './filtering'

interface ChainSearchProps {
  selectedChain?: Chain | null
  onChainSelect: (chain: Chain) => void
  otherSelectedChain?: Chain | null
}

const swapSound = new Audio('swap.mp3')

function ChainSearch({ selectedChain, onChainSelect, otherSelectedChain }: ChainSearchProps) {
  const { t } = useTranslation()
  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const allchains = useChainActiveList()

  const [audioPlay] = useAudioModeManager()

  const filteredTokens: Chain[] = useMemo(() => {
    return filterChains(Object.values(allchains), debouncedQuery)
  }, [allchains, debouncedQuery])

  const filteredSortedChain = useSortedChainsByQuery(filteredTokens, debouncedQuery)

  const handleChainSelect = useCallback(
    (chain: Chain) => {
      onChainSelect(chain)
      if (audioPlay) {
        swapSound.play()
      }
    },
    [audioPlay, onChainSelect],
  )

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    fixedList.current?.scrollTo(0)
  }, [])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        if (s === 'WBNB') {
          handleChainSelect(WBNB)
        } else if (filteredSortedChain.length > 0) {
          if (
            filteredSortedChain[0].name?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedChain.length === 1
          ) {
            handleChainSelect(filteredSortedChain[0])
          }
        }
      }
    },
    [filteredSortedChain, handleChainSelect, debouncedQuery],
  )

  return (
    <>
      <div>
        <AutoColumn gap="16px">
          <Row>
            <Input
              id="token-search-input"
              placeholder={t('Search chain name')}
              scale="lg"
              autoComplete="off"
              value={searchQuery}
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              onKeyDown={handleEnter}
            />
          </Row>
        </AutoColumn>
        {filteredSortedChain?.length > 0 ? (
          <Box margin="24px -24px">
            <ChainList
              height={390}
              chains={filteredSortedChain}
              onChainSelect={handleChainSelect}
              otherChain={otherSelectedChain}
              selectedChain={selectedChain}
              fixedListRef={fixedList}
            />
          </Box>
        ) : (
          <Column style={{ padding: '20px', height: '100%' }}>
            <Text color="textSubtle" textAlign="center" mb="20px">
              {t('No results found.')}
            </Text>
          </Column>
        )}
      </div>
    </>
  )
}

export default ChainSearch

import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  CHAININPUT = 'CHAININPUT',
  OUTPUT = 'OUTPUT',
  CHAINOUTPUT = 'CHAINOUTPUT',
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('bridge/selectCurrency')
export const selectChain = createAction<{ field: Field; chainId: number }>('bridge/selectChain')
export const switchCurrencies = createAction<void>('bridge/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('bridge/typeInput')
export const replaceBridgeState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
  inputChainId?: number
  outputChainId?: number
}>('bridge/replaceBridgeState')
export const setRecipient = createAction<{ recipient: string | null }>('bridge/setRecipient')

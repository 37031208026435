import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const tokens = serializeTokens()

console.log('tokenstokens:', tokens)

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'CAKEW LP',
    lpAddresses: {
      56: '0x2876992CDD3B48a08375768D7FC7f4ac7154D114',
      10001: '0x8a521189BfEDf99b1Dc84BC21571cF8B799DbD4D',
    },
    token: tokens.cakew,
    quoteToken: tokens.cakew,
  },
  {
    pid: 1,
    lpSymbol: 'BNB-USDT',
    lpAddresses: {
      56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
      10001: '0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae',
    },
    hidden: true,
    token: tokens.usdt,
    quoteToken: tokens.weth,
  },
  {
    pid: 2,
    lpSymbol: 'BNB-USDT',
    lpAddresses: {
      56: '0x5fd47583ED595D38283c854B7A1FDa10D14157C9',
      10001: '0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae',
    },
    token: tokens.usdt,
    quoteToken: tokens.weth,
  },
  {
    pid: 3,
    lpSymbol: 'BNB-CAKEW',
    lpAddresses: {
      56: '0x76e4ba6ce292c6f83d46577d7b52ecfbe9b40357',
      10001: '',
    },
    token: tokens.cakew,
    quoteToken: tokens.weth,
    baseLiq: 'https://pancakeswap.finance/add',
  },
]

export default farms

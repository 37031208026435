import React from 'react'
import styled from 'styled-components'
// import { Spinner } from '@cakewswap/uikit';
import { CakeWLogo } from 'components/Logo/CakeW'
import Page from '../Layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <CakeWLogo width={50} height={60} />
      <b>Loading...</b>
    </Wrapper>
  )
}

export default PageLoader

import { getAddress } from '@ethersproject/address'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { farmsConfig } from 'config/constants'
import useRefresh from 'hooks/useRefresh'
import { deserializeToken } from 'state/user/hooks/helpers'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, nonArchivedFarms } from '.'
import { State, SerializedFarm, DeserializedFarmUserData, DeserializedFarm, DeserializedFarmsState } from '../types'

const deserializeFarmUserData = (farm: SerializedFarm): DeserializedFarmUserData => {
  return {
    allowance: farm?.userData ? new BigNumber(farm?.userData.allowance) : BIG_ZERO,
    tokenBalance: farm?.userData ? new BigNumber(farm?.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm?.userData ? new BigNumber(farm?.userData.stakedBalance) : BIG_ZERO,
    earnings: farm?.userData ? new BigNumber(farm?.userData.earnings) : BIG_ZERO,
  }
}

const deserializeFarm = (farm: SerializedFarm): DeserializedFarm => {
  const {
    baseLiq,
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    quoteTokenPriceUsdt,
    tokenPriceUsdt,
    hidden,
  } = farm || {}

  return {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    baseLiq,
    hidden,
    quoteTokenPriceUsdt,
    tokenPriceUsdt,
    token: deserializeToken(farm?.token),
    quoteToken: deserializeToken(farm?.quoteToken),
    userData: deserializeFarmUserData(farm),
    tokenAmountTotal: farm?.tokenAmountTotal ? new BigNumber(farm?.tokenAmountTotal) : BIG_ZERO,
    lpTotalInQuoteToken: farm?.lpTotalInQuoteToken ? new BigNumber(farm?.lpTotalInQuoteToken) : BIG_ZERO,
    lpTotalSupply: farm?.lpTotalSupply ? new BigNumber(farm?.lpTotalSupply) : BIG_ZERO,
    tokenPriceVsQuote: farm?.tokenPriceVsQuote ? new BigNumber(farm?.tokenPriceVsQuote) : BIG_ZERO,
    poolWeight: farm?.poolWeight ? new BigNumber(farm?.poolWeight) : BIG_ZERO,
  }
}

export const usePollFarmsPublicData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))
  }, [includeArchive, dispatch, slowRefresh])
}

export const usePollFarmsWithUserData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

/**
 * Fetches the "core" farm data used globally
 * 2 = CakeW-ETH LP
 * 1 = USDT-BNB LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync([1, 2]))
  }, [dispatch, fastRefresh])
}

export const useFarms = (): DeserializedFarmsState => {
  const farms = useSelector((state: State) => state.farms)

  const deserializedFarmsData = farms.data.map(deserializeFarm)
  const { loadArchivedFarmsData, userDataLoaded } = farms
  return {
    loadArchivedFarmsData,
    userDataLoaded,
    data: deserializedFarmsData,
  }
}

export const useFarmFromPid = (pid: number): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))

  return deserializeFarm(farm)
}

export const useFarmFromLpSymbol = (lpSymbol: string): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return deserializeFarm(farm)
}

export const useFarmUser = (pid): DeserializedFarmUserData => {
  const { userData } = useFarmFromPid(pid)
  const { allowance, tokenBalance, stakedBalance, earnings } = userData
  return {
    allowance,
    tokenBalance,
    stakedBalance,
    earnings,
  }
}

// Return the base token price for a farm, from a given pid
export const useUSDTPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid)
  return farm && new BigNumber(farm?.tokenPriceUsdt)
}

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol)
  const farmTokenPriceInUsd = useUSDTPriceFromPid(farm?.pid)
  let lpTokenPrice = BIG_ZERO

  if (farm?.lpTotalSupply.gt(0) && farm?.lpTotalInQuoteToken.gt(0)) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm?.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm?.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(farm?.lpTotalSupply)
    lpTokenPrice = overallValueOfAllTokensInFarm?.div(totalLpTokens)
  }

  return lpTokenPrice
}

export const usePollFarmsData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

// /!\ Deprecated , use the BUSD hook in /hooks

export const usePriceEthUsdt = (): BigNumber => {
  const farm = useFarmFromPid(1)
  return new BigNumber(farm?.tokenPriceUsdt || '0')
}

export const usePriceCakeWUsdt = (): BigNumber => {
  // const cakeWETHFarm = useFarmFromPid(0)
  const cakeWETHFarm = useFarmFromPid(2)

  const cakePriceUSDTAsString = cakeWETHFarm?.tokenPriceUsdt

  const cakePriceUSDT = useMemo(() => {
    return new BigNumber(cakePriceUSDTAsString)
  }, [cakePriceUSDTAsString])

  return cakePriceUSDT
}

export const usePriceTokenSystemUsdt = (): BigNumber => {
  const farm = useFarmFromPid(3)

  const priceString = farm?.tokenPriceUsdt ?? '0'

  const priceUSDT = useMemo(() => new BigNumber(priceString), [priceString])

  return priceUSDT
}

export const usePricesFarm = () => {
  const farms = useSelector((state: State) => state.farms.data)

  return farms.reduce((prices, farm) => {
    if (!farm?.quoteToken?.address || !farm?.token?.address) {
      return prices
    }

    const tokenAddress = getAddress(farm?.token?.address)
    const quoteTokenAddress = getAddress(farm?.quoteToken?.address)

    /* eslint-disable no-param-reassign */
    if (!prices[quoteTokenAddress]) {
      prices[quoteTokenAddress] = new BigNumber(farm?.quoteTokenPriceUsdt).toNumber()
    }

    if (!prices[tokenAddress]) {
      prices[tokenAddress] = new BigNumber(farm?.tokenPriceUsdt).toNumber()
    }

    /* eslint-enable no-param-reassign */
    return prices
  }, {})
}

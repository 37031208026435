import React from 'react'
import { Currency, Pair } from '@cakewswap/sdk'
import { Button, ChevronDownIcon, Text, useModal, Flex } from '@cakewswap/uikit';
import styled from 'styled-components'
import { Chain } from 'types';
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ChainSearchModal from 'components/SearchModal/ChainSearchModal';

import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { ChainLogo, CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { RowBetween } from '../Layout/Row'
import { Input as NumericalInput } from './NumericalInput'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const ChainSelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0;
`

const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0;
`
const LabelRow = styled.div<any>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
`
const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
`
const Container = styled.div<{ hideInput: boolean }>`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.input};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`
interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean

  onChainSelect: (chain: Chain) => void
  chain?: Chain | null
  disableChainSelect?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  onChainSelect,
  chain,
  disableChainSelect,
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()
  const translatedLabel = label || t('Input')

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )

  const [onPresentChainModal] = useModal(
    <ChainSearchModal
      onChainSelect={onChainSelect}
      selectedChain={chain}
    />
  )

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        <LabelRow>
          <RowBetween>
            <Text fontSize="14px">{translatedLabel}</Text>
            {account && (
              <Text onClick={onMax} fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                {!hideBalance && !!currency && selectedCurrencyBalance
                  ? t('Balance: %amount%', { amount: selectedCurrencyBalance?.toSignificant(6) ?? '' })
                  : ' -'}
              </Text>
            )}
          </RowBetween>
        </LabelRow>
        <InputRow selected={disableCurrencySelect}>
          <RowBetween>
            <ChainSelectButton
              selected={!!chain}
              className="open-chain-select-button"
              onClick={() => {
                if (!disableChainSelect) {
                  onPresentChainModal()
                }
              }}
            >
              <Flex alignItems="center" justifyContent="space-between">
                {chain ? (
                  <ChainLogo chain={chain} size="24px" style={{ marginRight: '8px' }} />
                ) : null}
                <Text id="pair">
                  {(chain && chain.name && chain.name.length > 20
                    ? `${chain.name.slice(0, 4)}...${chain.name.slice(
                        chain.name.length - 5,
                        chain.name.length,
                      )}`
                    : chain?.name) || t('Select a chain')}
                </Text>
                {!disableCurrencySelect && <ChevronDownIcon />}
              </Flex>
            </ChainSelectButton>
            <CurrencySelectButton
              selected={!!currency}
              className="open-currency-select-button"
              onClick={() => {
                if (!disableCurrencySelect) {
                  onPresentCurrencyModal()
                }
              }}
            >
              <Flex alignItems="center" justifyContent="space-between">
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                ) : null}
                {pair ? (
                  <Text id="pair">
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </Text>
                ) : (
                  <Text id="pair">
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length,
                        )}`
                      : currency?.symbol) || t('Select a currency')}
                  </Text>
                )}
                {!disableCurrencySelect && <ChevronDownIcon />}
              </Flex>
            </CurrencySelectButton>
          </RowBetween>
        </InputRow>
        <InputRow selected={disableCurrencySelect}>
          <NumericalInput
            className="token-amount-input"
            value={value}
            onUserInput={(val) => {
              onUserInput(val)
            }}
          />
          {account && currency && showMaxButton && label !== 'To' && (
            <Button onClick={onMax} scale="sm" variant="text">
              MAX
            </Button>
          )}
        </InputRow>
      </Container>
    </InputPanel>
  )
}

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
    flex-direction: row;
  }
`

const Footer = () => {
  // const { t } = useTranslation()
  return <Wrapper />
}

export default Footer

import React from 'react'
import { Menu as UikitMenu } from '@cakewswap/uikit';
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceTokenSystemUsdt } from 'state/farms/hooks'
import config from './config'
import UserMenu from './UserMenu'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const price = usePriceTokenSystemUsdt()
  const { currentLanguage, setLanguage, t } = useTranslation()

  return (
    <UikitMenu
      userMenu={<UserMenu />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakewPriceUsd={price.toNumber()}
      links={config(t)}
      logoText={t('logotext')}
      extra={<div />}
      {...props}
    />
  )
}

export default Menu

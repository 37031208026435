export class Chain {
  public readonly rpc: string

  public readonly chainId: number

  public readonly name: string

  public readonly logoURI?: string

  public readonly explore?: string

  public static readonly WBNB: Chain = new Chain(
    'Wrapped BNB',
    56,
    'https://www.bnbchain.org',
    'https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png',
    'https://bscscan.com',
  )

  /**
   * Constructs an instance of the base class `Chain`. The only instance of the base class `Chain` is `Chain.WBNB`.
   * @param name name of the Chain
   * @param chainId chainId of the Chain
   * @param rpc of the Chain
   */
  protected constructor(name: string, chainId: number, rpc: string, logoURI?: string, explore?: string) {
    this.name = name
    this.chainId = chainId
    this.rpc = rpc
    this.logoURI = logoURI
    this.explore = explore
  }

  /**
   * Returns true if the two Chains are equivalent, i.e. have the same chainId and address.
   * @param other other Chain to compare
   */
  public equals?(other: Chain): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }

    return this.chainId === other.chainId
  }
}

export const WBNB = Chain.WBNB

export function chainEquals(chainA: Chain, chainB: Chain): boolean {
  if (chainA instanceof Chain && chainB instanceof Chain) {
    return chainA.equals(chainB)
  }
  if (chainA instanceof Chain) {
    return false
  }
  if (chainB instanceof Chain) {
    return false
  }
  return chainA === chainB
}

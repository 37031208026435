import { useMemo } from 'react'
import { Chain } from 'types'
import { Token } from '@cakewswap/sdk'
import { isAddress } from '../../utils'

export function filterChains(chains: Chain[], search: string): Chain[] {
  if (search.length === 0) return chains

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0)

  if (lowerSearchParts.length === 0) {
    return chains
  }

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((s_) => s_.length > 0)

    return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)))
  }

  return chains.filter((token) => {
    const { chainId, name } = token
    return (chainId && matchesSearch(chainId.toString())) || (name && matchesSearch(name))
  })
}

export function filterTokens(tokens: Token[], search: string): Token[] {
  if (search.length === 0) return tokens

  const searchingAddress = isAddress(search)

  if (searchingAddress) {
    return tokens.filter((token) => token.address === searchingAddress)
  }

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0)

  if (lowerSearchParts.length === 0) {
    return tokens
  }

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((s_) => s_.length > 0)

    return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)))
  }

  return tokens.filter((token) => {
    const { symbol, name } = token
    return (symbol && matchesSearch(symbol)) || (name && matchesSearch(name))
  })
}

export function useSortedTokensByQuery(tokens: Token[] | undefined, searchQuery: string): Token[] {
  return useMemo(() => {
    if (!tokens) {
      return []
    }

    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)

    if (symbolMatch.length > 1) {
      return tokens
    }

    const exactMatches: Token[] = []
    const symbolSubtrings: Token[] = []
    const rest: Token[] = []

    // sort tokens by exact match -> subtring on symbol match -> rest
    tokens.map((token) => {
      if (token.symbol?.toLowerCase() === symbolMatch[0]) {
        return exactMatches.push(token)
      }
      if (token.symbol?.toLowerCase().startsWith(searchQuery.toLowerCase().trim())) {
        return symbolSubtrings.push(token)
      }
      return rest.push(token)
    })

    return [...exactMatches, ...symbolSubtrings, ...rest]
  }, [tokens, searchQuery])
}


export function useSortedChainsByQuery(chains: Chain[] | undefined, searchQuery: string): Chain[] {
  return useMemo(() => {
    if (!chains) {
      return []
    }

    const chainIdMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)

    if (chainIdMatch.length > 1) {
      return chains
    }

    const exactMatches: Chain[] = []
    const symbolSubtrings: Chain[] = []
    const rest: Chain[] = []

    // sort tokens by exact match -> subtring on symbol match -> rest
    chains.map((chain) => {
      if (chain.chainId?.toString().toLowerCase() === chainIdMatch[0]) {
        return exactMatches.push(chain)
      }

      if (chain.name?.toLowerCase().startsWith(searchQuery.toLowerCase().trim())) {
        return symbolSubtrings.push(chain)
      }
      return rest.push(chain)
    })

    return [...exactMatches, ...symbolSubtrings, ...rest]
  }, [chains, searchQuery])
}
